import { theme } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import React from 'react';
import { Outlet } from 'react-router-dom';

const ManagementLayout: React.FC = () => {
    const { token: { colorBgContainer } } = theme.useToken();

    return (
        <>
            <Header style={{ padding: 0, background: colorBgContainer }} />
            <Content
                style={{
                    margin: '16px 16px',
                    overflow: 'hidden',
                    background: colorBgContainer,
                }}
            >
                <Outlet />
            </Content>
        </>
    );
};

export default ManagementLayout;