import { Layout, Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React from 'react';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ManagementLayout from './ManagementLayout';
import ComponentEnvironmentVersionReport from '../../pages/ComponentEnvironmentVersionReport';

const AppLayout: React.FC = () => {
    let location = useLocation();

    return (
        <Layout style={{ height: '100vh' }}>
            <Sider trigger={null} collapsible collapsed={false}>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={location ? [location.pathname] : ["/"]}
                    openKeys={["management"]}
                    items={[
                        {
                            key: '/',
                            label: <Link to="/">Home</Link>,
                        },
                        {
                            key: '/version-report',
                            label: <Link to="/version-report">Version Report</Link>,
                        },
                        {
                            key: 'management',
                            label: 'Management',                            
                            children: [
                                {
                                    key: '/management/components',
                                    label: <Link to="/management/components">Components</Link>,
                                },
                                {
                                    key: '/management/applications',
                                    label: <Link to="/management/applications">Applications</Link>,
                                },
                                {
                                    key: '/management/application-groups',
                                    label: <Link to="/management/application-groups">Application Groups</Link>,
                                },
                                {
                                    key: '/management/environments',
                                    label: <Link to="/management/environments">Environments</Link>,
                                },
                            ]
                        },
                    ]}
                />
            </Sider>
            <Layout>
                <Routes>
                    <Route path="/" element={<h1>Home</h1>} />
                    <Route path="/version-report" element={<ComponentEnvironmentVersionReport />} />
                    <Route path="/management" element={<ManagementLayout />}>
                        <Route path="components" element={<h1>Management - Components</h1>} />
                        <Route path="applications" element={<h1>Management - Applications</h1>} />
                        <Route path="application-groups" element={<h1>Management - Application Groups</h1>} />
                        <Route path="environments" element={<h1>Management - Environments</h1>} />
                    </Route>
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Layout>
        </Layout>
    );
};

export default AppLayout;