import "./ReportTable.css";
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Table } from 'antd';
// import { useComponents } from '../../hooks/use-components';
// import { useEnvironments } from '../../hooks/use-environments';
import { useReports } from '../../hooks/use-reports';
import { useWindowSize } from '../../hooks/use-window-size';

const ReportTable: React.FC = () => {
	const tableRef = useRef<any>({});

	const columns = [
		{
			title: "Application Group",
			dataIndex: ["applicationGroup", "name"],
			key: "applicationGroupId",
			width: 196,
			className: "top-aligned-cell",
			render: (value: any, row: any, index: number) => {
				const obj = {
					children: value,
					props: {} as any,
				};
				if (index >= 1 && value === componentsEnvironmentsVersionReport[index - 1].applicationGroup.name) {
					obj.props.rowSpan = 0;
				} else {
					for (let i = 0; index + i !== componentsEnvironmentsVersionReport.length && value === componentsEnvironmentsVersionReport[index + i].applicationGroup.name; i += 1) {
						obj.props.rowSpan = i + 1;
					}
				}
				return obj;
			}
		},
		{
			title: "Application",
			dataIndex: ["application", "name"],
			key: 'applicationId',
			width: 196,
			className: "top-aligned-cell",
			render: (value: any, row: any, index: number) => {
				const obj = {
					children: value,
					props: {} as any,
				};
				if (index >= 1 && value === componentsEnvironmentsVersionReport[index - 1].application.name) {
					obj.props.rowSpan = 0;
				} else {
					for (let i = 0; index + i !== componentsEnvironmentsVersionReport.length && value === componentsEnvironmentsVersionReport[index + i].application.name; i += 1) {
						obj.props.rowSpan = i + 1;
					}
				}
				return obj;
			}
		},
		{
			title: 'Component',
			dataIndex: ["component", "name"],
			key: 'componentId',
			width: 300
		},
		{
			title: 'DEV',
			key: "dev",
			dataIndex: ["environmentVersionMap", "env1", "version"],
			width: 128,
			align: 'center' as 'center'
		},
		{
			title: 'QA',
			key: "qa",
			dataIndex: ["environmentVersionMap", "env2", "version"],
			width: 128,
			align: 'center' as 'center'
		},
		{
			title: 'UAT',
			key: 'uat',
			dataIndex: ["environmentVersionMap", "env3", "version"],
			width: 128,
			align: 'center' as 'center'
		},
		{
			title: 'PROD',
			key: 'production',
			dataIndex: ["environmentVersionMap", "env4", "version"],
			width: 128,
			align: 'center' as 'center'
		},
	];

	// const [] = useWindowSize();
	const [ clientHeight, setClientHeight ] = useState<number>();

	const [ height ] = useWindowSize();
	useLayoutEffect(() => {
		if (tableRef != null) {
			const clientHeight = tableRef.current.parentElement.clientHeight;
			setClientHeight(clientHeight);
		}
	}, [height]);

	// const { data: components } = useComponents();
	// const { data: environments } = useEnvironments();
	const { data: componentsEnvironmentsVersionReport } = useReports("component-environment-version")

	return (
		<Table
			ref={tableRef}
			dataSource={componentsEnvironmentsVersionReport}
			rowKey="{id}"
			columns={columns}
			pagination={false}
			size='small'
			scroll={{ y: clientHeight ? clientHeight - 40 : 0 }}
			bordered
		/>
	);
};

export default ReportTable;
