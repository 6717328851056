import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useEnvironments = () => {
	return useQuery({
		queryKey: ["environment"],
		queryFn: async () => {
			const { data } = await axios.get(process.env.REACT_APP_API_BASE_URL + "/environment");
			return data;
		},
	});
}